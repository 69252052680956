import React, { Component, useState, useContext, useRef,useEffect } from 'react';
import "aos/dist/aos.css";
import { AuthService } from '../services/AuthService';
//import vector from '../images/vector.png';
import vector from '../images/lgimage.jpg';

import { UserService } from '../services/UserService';
import { Toast } from 'primereact/toast';
import {Link , Navigate, useNavigate} from 'react-router-dom';
import { Context } from "./Store";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { signInEvent } from './utils/facebookPixelEvent';
import { SignIn } from './facebookPixelEvent';
//import ReCAPTCHA from 'react-recaptcha';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';




const buttoneText ={
    buttonText: 'Login',     
}


const google_key = "6LeOP_UiAAAAADD3skNGlv7UaBdO70KAT55C1hmJ";

function AcceptUserUpdateDetails (){

     const [open, setOpen] = React.useState(false);


    const handleClose = () => {
        setOpen(false);
    };

    const [token, setToken] = useState(null);
    const [username, setUsername ] = useState('');
    const [password, setPassword] = useState('');
    const [user, setUser] = useState(null);
    const [state, dispatch] = useContext(Context);
    const toast = useRef(null);

    const [buttonstate, setButtonstate ] = useState(buttoneText);

    const [captchaVerified, setCaptchaVerified] = useState(false);
    const [lockLoginButton, setLockLoginButton] = useState(false);

    const [encryptedDatas, setEncryptedDatas] = useState(false);
    const [userId, setuserId] = useState(false);

    const [LockResponse, setLockResponse] = useState('');
    

    let authService = new AuthService();
    let userService = new UserService();
    let navigate = useNavigate();




    useEffect(() => {
        // Side effect code here
        const queryParam = new URLSearchParams(window.location.search)
        const encryptedDetails = queryParam.get("encryptedDetails");
        const newString = encryptedDetails.replaceAll(" ", "+")
        setEncryptedDatas(newString);
        //console.log("encryptedDetails",encryptedDetails);
        const id = queryParam.get("id");
        setuserId(id);
        //console.log("id",id);
        
      }, []);


    const handleVerifyCaptcha = (response) => {
        // This callback function will be called when the user verifies the captcha.
        //console.log("Captcha verified:", response);
        // Set the state to indicate that the captcha has been verified.
        setCaptchaVerified(true);
        setLockLoginButton(true);
        setButtonstate((s) => ({...s, buttonText: 'Login'}));
      };



    // const updatePassword =  (e) => {
    //     e.preventDefault();
    
    //     // const queryParam = new URLSearchParams(window.location.search)
    //     // const encryptedDetails = queryParam.get("encryptedDetails");
    //     // // const newString = encryptedDetails.substring(1, encryptedDetails.length - 1);
    //     // const newString = encryptedDetails.replaceAll(" ", "+")
    //     // const id = queryParam.get("id");
    //    // console.log("new",newString);
    //     //const jsonString = JSON.stringify(encryptedDetails);
    //    // console.log("data",`${encryptedDetails}`);
    //     //console.log("id",id);
    //     console.log("encryp", encryptedDatas);
    //     console.log("id", userId);
    //     axios.post(`${process.env.REACT_APP_BACKEND_HOST_URL}/user/${userId}/update`,
    //      {
    //       encryptedData:`${encryptedDatas}`

    //      }
    //      )
    //     .then( res => {
    //       this.toast.show({ severity: 'success', summary: 'Success', detail: 'Your details have been changed successfully', life: 3000 });
    //      // console.log(res)

    //     })
    //     .catch( res => {

       
    //     //console.log(res);
        
       
    //     })
        

       
    //   };
    


    const hanglePleaseverify =()=>{
        toast.current.show({ severity: 'error', summary: 'Log in failed', detail: 'Please Verify Captcha', life: 3000 })

    }




    const hangleLoggingIn =  () => {

        if (username == '' || password == ''){
            toast.show({ severity: 'info', summary: 'Invalid credentials', detail: 'Enter username and password', life: 3000 })
            return;
        }

        authService.getTokenWithUsernameAndPassword(username, password)
        .then(response => {
            //console.log("token",response.data);
            axios.post(
                `${process.env.REACT_APP_BACKEND_HOST_URL}/user/${userId}/update`,
                {
                    encryptedData: encryptedDatas
                },
                {
                    headers: {
                        'Authorization': `Bearer ${response.data.body}`
                    }
                }
            ).then((res)=>{
              //localStorage.setItem('user',);
              toast.current.show({ severity: 'success', summary: 'update', detail: 'update', life: 10000 })
  
              //navigate("/sign-in", { replace: true });
              
             console.log(res);
              
              
            })
            if(response.data.status == 200){
              // console.log("test event");
                signInEvent();
                setToken(response.data.body)
                localStorage.setItem('token', response.data.body);
                userService.getUser(response.data.body, username)
                .then(usr => {
                    if(usr.data.status == 200){
                      

                        localStorage.setItem('user', JSON.stringify(usr.data.body));
                        localStorage.setItem('isLoggedIn', true);
                        dispatch({
                            type: "LOG_IN", // The name of the reducer
                            payload: JSON.stringify(usr.data.body), // The new state of the reducer
                          });

                          setButtonstate((s) => ({...s, buttonText: 'login....'}));
                          

                          navigate("/dashboard", { replace: true });

                          SignIn();

                          setOpen(!open);
                    }else{
                             toast.current.show({ severity: 'error', summary: 'Log in failed', detail: 'Failed to log you in. please try again later', life: 3000 })
    
                    }
                   
                })
                .catch(error => {
                    //console.log(error);
                     toast.current.show({ severity: 'error', summary: 'Log in failed', detail: 'please try again later', life: 3000 })
                })
            }else{

                if(response.data.status === 403){

                    toast.current.show({ severity: 'error', summary: 'Log in failed', detail: `${response.data.message}`, life: 3000 })


                }
                if(response.data.status === 423){

                    setLockResponse(response.data.message)
                  
                  
                    toast.current.show({ severity: 'error', summary: 'Log in failed', detail: `${response.data.message}`, life: 3000 })


                }



                    

            }
            
        })
        .catch(error => {
            //console.log(error);
            // this.toast.show({ severity: 'error', summary: 'Log in failed', detail: 'Failed to log you in. please try again later', life: 3000 })
        });
    }
       
            return (
                
                <div >
                <Toast ref={toast} />
                     <section className="page-feature py-5">
                        <div className="container text-center">
                            <div className="row">
                                <div className="col-md-6">
                                    <h2 className=" text-left">Login</h2>
                                </div>
                                <div className="col-md-6">
                                    <div className="breadcrumb text-right">
                                        <Link to='/'><a >Home</a></Link>
                                        <span>/ Login</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="banner login-registration">
                        <div className="vector-img">
                            <img src={vector} alt=""/>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="content-box">
                                        <h2>Login Account to Accept changes</h2>
                                        <p>Please Login to Acccept changes you made to your personal information</p>
                                    </div>
                                    {/* <form action="#" className="sl-form"> */}
                                    <div className="sl-form">
                                        <div className="form-group">
                                            <label>Username</label>
                                            <input type="text" id='username' name='username' placeholder="username" required onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                setUsername(e.target.value)
                                                                                                                // this.setState((state) => (state.username= e.target.value))
                                                                                                                }}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Password</label>
                                            <input type="password" id='password' name='password' placeholder="Password" required onChange={(e) => {
                                                                                                                e.persist();
                                                                                                                setPassword(e.target.value)
                                                                                                                // this.setState((state) => (state.password= e.target.value))
                                                                                                                }}/>
                                        </div>
                                        {
                                            lockLoginButton === false 
                                            ? 
                                            <button className="btn btn-filled btn-round"  onClick={hanglePleaseverify}><span className="bh"></span> <span>{buttonstate.buttonText}</span></button>

                
                                            :
                                            <button className="btn btn-filled btn-round" onClick={hangleLoggingIn}><span className="bh"></span> <span>{buttonstate.buttonText}</span></button>


                                        }
                                        
                                

                                        
                                 <ReCAPTCHA
                                        sitekey={`${google_key}`}
                                        onChange={handleVerifyCaptcha}
                                    />

                                    {captchaVerified && <p>Captcha verified!</p>}
                                    
                                    <p className="notice"><Link to='/forgot-password'><a >Forgot Password?</a></Link></p>
                                    <p className="notice">Don’t have an account?<Link to='/sign-up'><a >Sign up now</a></Link></p>
                                   
                                    </div>
                                    {/* </form> */}
                                </div>
                            </div>
                        </div>
                    </section>
                
                </div>
            );
        }


export default AcceptUserUpdateDetails;